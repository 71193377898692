<template>
  <div class="justify-center full-height" style="max-width: 1980px;">
    <div class="q-pa-xl full-width row">
      <div class="row full-width q-mb-xl justify-between">
        <div class="text-h4">Reconnaissance</div>
      </div>
      <div class="row full-width items-start">
        <div class="col-8 row">
          <div class="row full-width">
            <q-form @submit="search" autocomplete="off">
              <div class="row full-width q-my-xl">
                <q-input v-model="username" label="Search For User" />
                <q-btn type="submit" color="primary" label="Search" class="q-mx-lg" />
              </div>
            </q-form>
          </div>
          <div class="row full-width" v-if="foundUser.uid">
            <q-list>
              <q-item>
                <q-item-section>
                  <q-item-label>{{ foundUser.username }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-btn outline color="primary" label="add" @click="addToReconMode" />
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
        <div class="col-4 row">
          <div class="text-h6 row full-width">Users in Reconnaissance</div>
          <q-list v-if="usersInRecon.length > 0">
            <q-item v-for="(user, i) in usersInRecon" :key="user.uid">
              <q-item-section>
                <q-item-label>{{ user.username }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn outline color="primary" label="Remove" @click="removeFromReconMode(user, i)" />
              </q-item-section>
            </q-item>
          </q-list>
          <div v-else class="text-caption text-center q-my-lg">
            No users in Reconnaissance Mode
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      foundUser: {},
      usersInRecon: []
    }
  },
  methods: {
    async search () {
      this.$q.loading.show()
      let res = await this.$axios.post(this.$consts.SEARCH_RECON_USER_URL, { username: this.username })
      this.$q.loading.hide()

      if (res.data.uid) {
        this.foundUser = res.data
      } else {
        this.$failureMsg(`Couldn't find matching user`)
      }
    },
    async addToReconMode () {
      this.$q.loading.show()

      try {
        await this.$axios.post(this.$consts.STORE_RECON_USER_URL, { uid: this.foundUser.uid, username: this.foundUser.username })
        this.$successMsg(`${this.foundUser.username} put in recon mode`)
        this.usersInRecon.push(this.foundUser)
        this.foundUser = {}
        this.username = ''
        this.$q.loading.hide()
      } catch (e) {
        this.$q.loading.hide()
        this.$failureMsg(`Failed to add to recon mode`)
      }
    },
    async removeFromReconMode (user, index) {
      this.$q.loading.show()

      try {
        await this.$axios.post(this.$consts.DESTROY_RECON_USER_URL, { uid: user.uid, username: user.username })
        this.$successMsg(`${user.username} removed from recon mode`)
        this.usersInRecon.splice(index, 1)
        this.$q.loading.hide()
      } catch (e) {
        this.$q.loading.hide()
        this.$failureMsg(`Failed to remove from recon mode`)
      }
    }
  },
  async created () {
    this.usersInRecon = (await this.$axios.get(this.$consts.FETCH_RECON_USER_URL)).data
  }
}
</script>
